.accordion {
  margin-bottom: 10px;
}

.accordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  user-select: none; /* Evita que el texto se seleccione accidentalmente */
}

.accordionHeader::after {
  content: "▼"; /* Flecha hacia abajo */
  font-size: 14px;
  color: green;
  transition: transform 0.3s ease;
  margin-left: 10px;
}

details[open] .accordionHeader::after {
  transform: rotate(180deg);
}


.accordionContent {
  padding: 10px;
  background: #fff; /* Fondo blanco */
}
