.root {
}

.media {
  width: 25%;
  max-width: 90px; 
  border-radius: 8px;
  margin-bottom: 30px;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.customLinkBlock{
  cursor: pointer;
}

@media (max-width: 768px) {
  .media {
    width: 40px; 
    height: auto;
    margin-right: 10px; 
  }

  .titleWithIcon {
    display: flex;
    align-items: center;
  }

  .text {
    width: auto;
  }
}
